import React from 'react';
import App from '../components/App';
import { SEO } from "../components/SEO/SEO";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const IndexPage = () => {

  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <SEO />
      <App />
    </>
  );
};

export default IndexPage;

// export const Head = () => (
//   <SEO />
// );